body {
  padding: 0;
  margin: 0;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:#d6d6d6;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header div a {
  padding: 1em;
  cursor: pointer;
}

header div a:hover {
  color: #ff21d640;
  padding: 1em;
}

.navigation-link a.active {
  color: #ff00d0a1;
  padding: 1em;
}

::selection {
  background: #ff21d620;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 0em;
  background: #0000;
}

::-webkit-scrollbar-track {
  background: #0000
}
::-webkit-scrollbar-button {
  background: #0000
}
