#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

main {
  font-size: 22pt !important;
}


#r3f-canvas canvas {
  position: fixed;
  width:  100%;
  height: 100%;
  top: 0;
  left: 0;
}

.App {
  text-align: center;
  position: absolute;
  top: 0;
}

.vr {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.fullScreen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.background {
  background:#ffffffb0;
}


.visual-theme {
  font-size: 1.85em !important;
  vertical-align: bottom;
}
.visual-theme._0 {
  padding-right: 0.3em;
}

.theme._0 main {
  color: #000000;
}
.theme._1 main {
  color: #ffffff;
}

#ui_2d__button_container button {
  background: none;
}

.theme._0 .ui_2d__button {
  border: 0.07em solid #00d5ff;
}

.theme._0 .vr-button {
  border: 0.07em solid #00d5ff !important;
}

.theme._1 .ui_2d__button  {
  border: 0.07em solid #df0ca7;
}

.theme._1 .close-button  {
  color: #df0ca7;
}

.theme._1 .vr-button {
  border: 0.07em solid #df0ca7 !important;
}


.vr-button {
  border-radius: 0.4em !important;
  font-size: 0.9em;
}

.close-button {
  color: white !important;
  margin-left: 1em;
  background: none;
  border: none;
  font-size: 2em;
  transform: translate(0, -10px) !important;
}

#ui_2d__button_container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  margin-right: 0.5vw;
  padding-bottom: 0.75em;
}



.ui_2d__button {
  color: white;
  vertical-align: top;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
  transform: none !important;
  
  z-index: 9999 !important;
  
  border-radius: 0.2em !important;
  cursor: pointer !important;

  font-size: 2em;
}

.ui_2d__settings {
  color: white;
  z-index: 9999999999;
  position: relative;
  padding: 1em;
  border-radius: 1em;
  margin-bottom: 1em;
}

button._2_glyph_icon {
  border: 0;
  padding: 0.4vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  font-size: 1.5em;
  cursor: pointer;
} 
 
#ui_2d__button_container .ui_2d__button {
    margin-right: 0.5vw;
}

.table {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  padding: 0.25em;
}


.ui_2d__settings .row .cell:first-child {
  max-width: 3.5em;
}

.cell {
  flex: 1;
}